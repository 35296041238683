import "./Signin.css"
import InputField from "../../UtilComponewnts/InputField/InputField";
const Signin=({setUser,setPassWord,setIsSignedIn,user,passWord})=>{
    const handelSubmit=()=>{
        if(user==="Official-Kazarmax" && passWord==="Kazarmax@123"){
            setIsSignedIn(true);
        }else{
            alert("Please Check your Credentials")
        }
    }
    return(
        <div className="SigninPage">
            <div className="SigninCard">
                <h3>Signin</h3>
                <InputField label="User Name" placeholder="User Name" setValue={setUser} thetype="text"/>
                <InputField label="Password" placeholder="Password"  setValue={setPassWord} thetype="password"/>
                <button className="Submit s-b" onClick={handelSubmit}>Submit</button>
            </div>
        </div>
        );
}

export default Signin;