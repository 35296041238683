import RadioButton from '../../UtilComponewnts/RadioButton/RadioButton'
// import InputField from '../../UtilComponewnts/InputField/InputField'
import axios from 'axios'
import { useState } from 'react';
import "./Resolve.css";
const Resolve=({setIsOpen,view})=>{
       const [isCompensation,setIsCompensation] = useState(null)
       const [typeRfund,setTypeRfund] = useState(null)
       const [isView,setIsView] = useState(false)
       const [serviceDetails,setServiceDetails] = useState("")
       const [FeedBack,setFeedback] = useState("")
    //    const [user,setUser] = useState("");
       const handelResolve=()=>{
         if(isCompensation===null || FeedBack===""){
             alert('please fill the form');
         }else if((isCompensation===true && serviceDetails==="")||(typeRfund===null && isCompensation===true) ){
             
            alert('please fill the form');
         }
         else{
            var url=`https://complaint.etark.in`;
            console.log(url);
            let r=isCompensation ? `Congratulations! your complaint has been successfully processed and as a token of apology, we are offering you a ${typeRfund===1?"Monetary Refund":typeRfund===2?"Product/Part replacement":typeRfund===3?"Free service":null} , ${serviceDetails}.  ${FeedBack}`:`We are sorry, but your complaint didn't qualify for a compensation. ${FeedBack}`;
            let re={
                Review:r,
                service_offered:serviceDetails,
                how_compensation_claim:FeedBack,
                whichservice:typeRfund===1?"Monetary Refund":typeRfund===2?"Product/Part replacement":typeRfund===3?"Free service":"no service provided"
 
            }
            axios.put(`https://strapi-oneshot.etark.in/kazarmaxes/${view.id}`, re).then(()=>{
                
           
               console.log(view);
         
         
         
         
         }).catch(()=>alert("Error"))
         
             console.log('enetr');
             var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 
 var raw = JSON.stringify({
   "from": "noreply.notifications@etark.in",
   "to": view.Email,
   "subject": `one Shot Resolution for your complaints order-on:${view.Orderno} on ${view.ProductName}`,
   "text":`${r}` ,
   "html":`
   <div style="width: 100%;height: auto;align-items: center;justify-content: center;"> 
   <center>
   <div style="width: 500px;background-color: #e5e8ee;align-items: center;justify-content: center;margin-left: auto;margin-right: auto;padding-buttom:10px">
   <div style="width: 100%;background-color: #245FD3;align-items: center;justify-content: center;">
   
   <h3 style="font-family: Poppins;align-self: center;margin-top: 10px;padding-top: 10px;
   font-style: normal;
   font-weight: bold;
   font-size: 35px;color: #FFFFFF;
   line-height: 60px;">Problem resolved!</h3>
    </div>


    <h4 style="font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding:20px;
   
    
    
    color: #545454;">${r}</h4>
    
    



<h4>Regards,
  Team kazarmax</h4>

<p>&copy; Copyright 2021. ETark Technology Pvt. Ltd. All rights reserved</p>

    </div>
    </center>
    </div>
 

`
   
   
 });
 
 var requestOptions = {
   method: 'POST',
   headers: myHeaders,
   body: raw,
   redirect: 'follow'
 };
 
 fetch("https://mailapi.etark.in/mail/compose", requestOptions)
   .then(response =>response.text())
   .then(result => {console.log(result);window.location.href = '/'})
   .catch(error =>{ console.log('error', error);alert('something wrong with server try again')});
         }
  
       }
       return ( 
       <div className="ResolveBackDrop">
            <div className="ResolveModal">
                <button className="cross" onClick={()=>setIsOpen(false)}><img src="cross.png" width="20" alt="co"/></button>
                <div className="ModalLeft"></div>
                <div className="ResolveBody">
                    <h1>Resolve Complaints</h1>
                    <h5>Areas That are Defaulted</h5>
                    {!isView?<button className="Submit" onClick={()=>setIsView(true)}>View</button>:null}
                    {
                        isView?(<div>
           {
                                    view.Q1===null && view.Q2===null && view.Q3===null && view.Q4===null && view.Q5===null && view.Q6===null && view.Q7===null && view.Q8===null && view.Q9===null?
                                    <p> product is not Order directly from www.kazarmax.com </p>:view.maxscore===view.Score?<p> user had not done any misstakes </p>:null
                                }
          

                        <p>
                     
                                { view.Q1===false?`Did you respond to the free size consultation call of Kazarmax? ${view.Q1===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q2===true?`Did you choose a size other than the one suggested by the Sizing Expert? ${view.Q2===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q3===false?`Did you face a sizing error in spite of ordering as per the Sizing Expert’s advice? ${view.Q3===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q4===false?`Did you receive a different product than the one you had ordered?  ${view.Q4===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q5===false?`Did you receive the delivered product in a physically damaged/ tampered state or the packaging tampered/soiled? ${view.Q5===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q6===false?`Is some item/accessories missing from your delivered package? ${view.Q6===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q7===false?`Are you reporting about your problem to us within 48 hours from the date of delivery? ${view.Q7===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q8===true?`Have you tried the product? ${view.Q8===true?"Yes":"No"}`:null}</p>
                            <p>{view.Q9===true?`Is the product's original packaging box (box and tags) missing? ${view.Q9===true?"Yes":"No"}`:null}</p>

                        </div>):null
                    }
                    <RadioButton label="Are you providing any compensation to this user?" val={isCompensation} setValue={setIsCompensation}/>
                    {isCompensation?<div>
                        <div className="radioCheck">
                            <input type="radio" name="solution" onChange={()=>{setTypeRfund(1)}}/>
                            <label className="label">Monetary</label>
                        </div>
                        <div className="radioCheck">
                            <input type="radio" name="solution" onChange={()=>{setTypeRfund(2)}}/>
                            <label className="label">Product/Part replacement</label>
                        </div>
                        <div className="radioCheck">
                            <input type="radio" name="solution" onChange={()=>{setTypeRfund(3)}}/>
                            <label className="label">Free service</label>
                        </div>
                    </div>:null}
                    {isCompensation ?<input className="TextInput" type="text" maxLength="200" onChange={(e)=>{setServiceDetails(e.target.value)}} placeholder="Please mention the compensation you are offering" />:null}
                    <div className="InputFieldContainer">
                        {
                            isCompensation? <label>Please describe the details of how the user would be able to claim the compensation</label>:<label>Please state your reasons why you are denying compensation for this complaint in details</label>
                        }
                       
                        <textarea  onChange={(e)=>{setFeedback(e.target.value)}} maxLength="500" className="FeedBackText" />
                    </div>
                    <button className="Submit" onClick={handelResolve}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Resolve;