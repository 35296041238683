import "./InputField.css"
const InputField=({label,placeholder,setValue,thetype})=>{
    return(
        <div className="InputFieldContainer">
            <label>{label}</label>
            <input type={thetype} placeholder={placeholder} onChange={(e)=>{setValue(e.target.value)}} />
        </div>
    );
}

export default InputField;