import Dashboard from './Pages/DashBoard/Dashboard'

function App() {
  return (
    <div className="App">
        <Dashboard/>
    </div>
  );
}

export default App;
