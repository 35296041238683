import "./DashBoard.css"
import Resolve from '../Resolve/Resolve.js'
import Signin from "../Signin/Signin";
import React,{ useState,useEffect } from "react";
const Dashboard = () => {
    const rows = ["Order No.", "Product", "Product's age", "Price", "Product Size","Photo" ,"Complaint details","Compliance score of user", "Level of fakeness in complaint", "True merit in complaint", "Status"];
    const [isOpen,setIsOpen]=useState(false)
    const [isShow,setShow]=useState(false)
    const [img,setimg]=useState()
    const [detail,setdetails]=useState()
    const [isview,setview]=useState(false)
    const [isview2,setview2]=useState(false)
    const [feedback,setFeedback]=useState()
    const [service,setservice]=useState()
    const [servicedetails,setservicedetails]=useState()
    const [OpenIssue,setOpenIssue]=useState(0)
    const [complaints,setComplaints]=useState([])
    const [user,setUser]=useState("");
    const [passWord,setPassWord]=useState("");
    const [isSignedIn,setIsSignedIn]=useState(localStorage.getItem("tokens") || false);
    const HandelResolve=(e)=>{
       console.log("Clicked");
       setIsOpen(true);
       setOpenIssue(e.target.name);
    }
    useEffect(()=>{
        console.log("bhBHAS");
        
        localStorage.setItem("tokens", true);
        fetch(`https://strapi-oneshot.etark.in/kazarmaxes`)
        .then(response => response.json())
        .then(data => {
            setComplaints(data)
            console.log(data);
        }
        ).catch((err)=>{
            console.log("Can't Find Your Complain")
        });
    },[]);
    if(!isSignedIn){
        return <Signin setUser={setUser} setPassWord={setPassWord} setIsSignedIn={setIsSignedIn} user={user} passWord={passWord}/>;
    }
    return (
        <div className="dashboardContainer">
            <div className="dashboardLeft">
                <div className="slidebar">
                    <div>
                         <img src="logo.png" width="40" className="logo" alt="logo"/> 
                    </div>
                 <div>
                <p className="logopara">  One Shot Resolution</p>      
                 </div>
                
                </div>
            
            </div>
            <div className="dashboardRight">
                <div></div>
                <h2 className="dashboardIntro">Track Complaints</h2>
                <div className="dashboard">
                    <table className="table">
                        <thead>
                        <tr className="tableHead">
                            {rows.map((row, i) =><th key={i}  className="tableData">{row}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            complaints.map((c,key)=>{
                                return(
                                    <tr key={c.Orderno} className="tableRow">
                                        <td className="tableData">{c.Orderno}</td>
                                        <td className="tableData">{c.ProductName}</td>
                                        <td className="tableData"> {c.year}yrs {c.month}month</td>
                                        <td className="tableData">INR {c.Price}</td>
                                        <td className="tableData">{c.Size}</td>
                                        <td className="tableData">{c.url?<button className="bt" onClick={()=>{setimg(c.url);setShow(true); }}><img src="image.png" width="20" alt="img" /></button>:null}
           
                           
                                        </td>
                                        <td className="tableData">
                                            <button className="bt" onClick={()=>{setdetails(c.Detail);setview(true)}}>view</button>
                                        
                                                                       
                   
                                        
                                        </td>
                                        <td className="tableData">{c.maxscore==='0'?0.00:(Math.round(c.Score * 100) / c.maxscore).toFixed(2)}%
                 
                                        </td>
                                        <td className="tableData">{!c.Fakeness?null:c.Fakeness+'%'}</td>
                                        <td className="tableData">{!c.Fakeness?c.maxscore==='0'?0.00 :(Math.round(c.Score * 100) / c.maxscore).toFixed(2):c.maxscore==='0'?0.00 :(Math.round(c.Score *100 *c.Fakeness) / c.maxscore).toFixed(2)}%</td>
                                        <td className="tableData">
                                            {c.Review? <button className="Submited" onClick={()=>{
                                                setservice(c.whichservice);
                                                setservicedetails(c.service_offered);
                                                setFeedback(c.how_compensation_claim);
                                                setview2(true);
                                            }}   name={key} >Resolved</button>: <button className="Submit" name={key} onClick={HandelResolve}>Resolve</button>
                                            }
                                            
                                           </td>
                                    </tr>
                                )
                            }).reverse()
                        }
                        </tbody>
                    </table>
                                                 
                    {
                                            isShow?         <div className="box" onClick={()=>{setShow(false)}}>    <div className="model">
                                                
                                        <div ><img src={img} className="img" alt="img" /></div>
                  </div>  </div>:null

                                        }
                                         {
                                            isview?  <div className="box" onClick={()=>{setview(false)}}>           
                                            <div className="model2" >
                                            
                                    <h3 className="detail">Detail of complaints: </h3>
                                    <p className="para">
                                        {detail}
                                    </p>
                </div></div>:null


                                        }
                                         {
                                            isview2?    
                                            
                                            <div className="box" onClick={()=>{setview2(false)}}>                <div className="model2" >
                                            
                                    <h3 className="detail"> {service} </h3>
                                    
                                        {servicedetails===''?null:<p className="para"><strong>Service Details:</strong> ${servicedetails} </p>}
                                   
                                    <p><strong>FeedBack:</strong> {feedback}</p>
                </div>
                                                </div>
                            :null


                                        }
                </div>
            </div>
            {isOpen?<Resolve setIsOpen={setIsOpen} view={complaints[OpenIssue]}/>:null}
        </div>
    )
}

export default Dashboard;